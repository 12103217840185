import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

// Components
import TournamentFAQ from 'components/tournament_details/TournamentFAQ';
import TournamentTeamList from 'components/tournament_details/TournamentTeamList';
import TournamentTimeline from 'components/tournament_details/TournamentTimeline';
import TournamentRegistrationForm from 'components/tournament_details/TournamentRegistrationForm';

// Apis
import useFetch from 'utils/hooks/useFetch'
import { GET_TOURNAMENT, GET_TOURNAMENT_SCHEDULE, GET_TOURNAMENT_FAQS } from 'api';

function TournamentDetails() {
  const { data: tournament, fetch: getTournament } = useFetch(GET_TOURNAMENT)
  const { data: schedules, fetch: getTournamentSchedules } = useFetch(GET_TOURNAMENT_SCHEDULE)
  const { data: tournamentFaqs, fetch: getTournamentFaqs } = useFetch(GET_TOURNAMENT_FAQS)

  const { t } = useTranslation();
  const { id } = useParams();

  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false);
  
  useEffect(() => {
    getTournament({
      params: {
        id
      }
    })
  }, [
    getTournament,
    id
  ])

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentSchedules({
        params: {
          tournament_id: tournament.id
        },
        data: {
          sort: ['id', 'ASC'],
        }
      })
    }
  }, [
    tournament,
    getTournamentSchedules
  ])

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentFaqs({
        params: {
          tournament_id: tournament.id
        }
      })
    }
  }, [
    tournament,
    getTournamentFaqs
  ])

  return (
    <div className='pb-8 bg-center bg-[url("https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1edf2a8833ec42d9dfa27756284e6ef6413883cdb62e3a07e2e43faaf1cdaad25124dbafd033c0257bc59c86af3982c3439c848e633c83924be7cc30d531dc8dc255bfaf471f60630ed8eb0e89e3efda8e66866fe0029a816a553c3acdfe82ed5dc37715d92ebf699fce925bd6f4085199bc4ba1e0f275d3cb1c/file-1722086703913.png")]'>

      <section>
        <div className='relative z-20'>
          <img src={tournament && tournament.header_image_url} alt="main" className='w-full object-contain object-center' />
        </div>
      </section>

      {
        tournament && tournament.hero_left_image_url && (
          <img className='top-24 left-0 opacity-20 fixed w-48 sm:w-96' src={tournament && tournament.hero_left_image_url}/>
        )
      }
      {
        tournament && tournament.hero_right_image_url && (
          <img className='bottom-0 right-0 opacity-20 fixed w-48 sm:w-96' src={tournament && tournament.hero_right_image_url} />
        )
      }

      <section>
        <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
          <p className='text-center text-xl sm:text-3xl font-bold app-animation-translate-start'>
            About {tournament && tournament.title}
          </p>
          <p className='text-center text-sm sm:text-base mx-auto app-animation-translate-start' dangerouslySetInnerHTML={{ __html: tournament && tournament.description }} />
        </div>
      </section>

      <section className='app-animation-scale-start'>
        {
          tournament && tournament.contents && tournament.contents.map((content) => {
            if (content && content.is_description_image && content.description_image_url) {
              return (
                <div className='max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8'>
                  {
                    content.with_title && (
                      <p className='text-center text-xl sm:text-3xl font-bold mb-12'>
                        {content && content.title}
                      </p>
                    )
                  }
                  <a target="_blank" rel="noopener noreferrer" href={content && content.description_image_link || '#'}>
                    <img src={content.description_image_url} alt="main" className='w-full object-contain object-center md:px-24' />
                  </a>
                </div>
              )
            }
            if (content && !content.is_description_image) {
              return (
                <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
                  {
                    content.with_title && (
                      <p className='text-center text-xl sm:text-3xl font-bold'>
                        {content && content.title}
                      </p>
                    )
                  }
                  <p className='text-center text-sm sm:text-base mx-auto' dangerouslySetInnerHTML={{ __html: content && content.description }} />
                </div>
              )
            }
          })
        }
      </section>

      <section className='app-animation-translate-start'>
        {
          tournament && tournament.is_wave && schedules && schedules.length > 0 && (
            <TournamentTimeline selectedTournamentTimelines={schedules} />
          )
        }
        {
          tournament && !tournament.is_wave && schedules && schedules.length > 0 && (
            <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
              <p className='text-center text-xl sm:text-3xl font-bold'>
                {schedules[0] && schedules[0].title}
              </p>
              <p className='text-center text-sm sm:text-base mx-auto' dangerouslySetInnerHTML={{ __html: schedules[0] && schedules[0].description_html }} />
            </div>
          )
        }
      </section>

      <section>
        <div className='app-main-container mx-auto space-y-8 app-animation-translate-start'>
          <div className='text-center'>
            {isRegistrationFormOpen ? (
              <TournamentRegistrationForm id={id} setIsRegistrationFormOpen={setIsRegistrationFormOpen} schedules={schedules} tournament={tournament} />
            ) : (
              <button onClick={() => setIsRegistrationFormOpen(!isRegistrationFormOpen)} className={`uppercase text-sm sm:text-base bg-${tournament && tournament.theme_color || 'red'}-500 hover:bg-${tournament && tournament.theme_color || 'red'}-700 text-white font-bold py-4 px-8 rounded-full`} >
                {t('registerNow')}
              </button>
            )}
          </div>
          <div className='text-center'>
            <a href={tournament && tournament.rule_book_url || '#'} target="_blank" rel="noopener noreferrer" className="bg-transparent uppercase text-sm sm:text-base hover:bg-gray-200 font-semibold py-2 px-8 border border-black border-2 rounded-full cursor-pointer">
              {t('rulesBook')}
            </a>
          </div>
        </div>
      </section >

      <TournamentTeamList tournament={tournament} schedules={schedules} />

      <section className='app-animation-translate-start'>
        {
          tournamentFaqs && tournamentFaqs.length > 0 && (
            <TournamentFAQ tournamentFaqs={tournamentFaqs} />
          )
        }
      </section>

    </div >
  )
}

export default TournamentDetails