import React from 'react'
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';

// Icon
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowIcon';

function TournamentFAQ({ tournamentFaqs }) {
  const { t } = useTranslation();

  const AccordionItem = ({ header, order, ...rest }) => {
    return (
      <Item
        {...rest}
        header={({ state: { isEnter } }) => (
          <div className='flex flex-row justify-between items-center'>
            <div>
              <p className='text-sm sm:text-base font-bold'>{header}</p>
            </div>
            <div>
              <KeyboardArrowDownIcon
                className={`h-3 sm:h-4 lg:h-5 w-3 sm:w-4 lg:w-5 fill-black ml-auto transition-transform duration-200 ease-out ${isEnter && "rotate-180"
                  }`}
              />
            </div>
          </div>
        )}
        className={`${order === 0 && "border-t-2"} border-b-2 border-gray`}
        buttonProps={{
          className: ({ isEnter }) =>
            `w-full p-4 text-left hover:bg-gray-100 ${isEnter && "bg-gray-200"}`
        }}
        contentProps={{
          className: "transition-height duration-200 ease-out"
        }}
        panelProps={{ className: "p-4" }}
        initialEntered={order === 0 ? true : false}
      />
    )
  };

  return (
    <section>
      <div className='max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8'>
        <p className='text-center text-xl sm:text-3xl font-bold'>
          {t('frequentlyAskedQuestions')}
        </p>
        <Accordion transition transitionTimeout={200}>
          {tournamentFaqs && tournamentFaqs.length > 0 && tournamentFaqs.map((faq, i) => {
            return (
              <AccordionItem header={faq.question} key={i} order={i}>
                <p className='text-sm sm:text-base'>{faq.answer}</p>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </section>
  )
}

export default TournamentFAQ